import require from '@/utils/require';
import { network, auto } from '@/config/geteway';
import Axios from 'axios';
// 订单列表
export const orderlistdata = function(data = {}) {
  return require({
    url: `${network}/${auto}/orderinfo/findWebOrderInfoByPageDraft`,
    method: 'POST',
    data
  });
};
// 订单详情
export const orderdateils = function(data = {}) {
  return require({
    url: `${network}/${auto}/orderinfo/lookOrderInfo`,
    method: 'POST',
    data
  });
};
// 订单详情
export const findCustomerGoodsByPage = function(data = {}) {
  return require({
    url: `${network}/${auto}/customergoods/findCustomerGoodsByPage`,
    method: 'POST',
    data
  });
};
// 批量添加箱号
export const createOrderCaseList = function(data = {}) {
  return require({
    url: `${network}/${auto}/ordercase/createOrderCaseList`,
    method: 'POST',
    data
  });
};
// 编辑保存
export const editSaveOrderInfo = function(data = {}) {
  return require({
    url: `${network}/${auto}/orderinfo/editSaveOrderInfo`,
    method: 'POST',
    data
  });
};
// 订单取消
export const draftCancelOrderInfo = function(data = {}) {
  return require({
    url: `${network}/${auto}/orderinfo/draftCancelOrderInfo`,
    method: 'POST',
    data
  });
};
// 确认计重
export const affirmCounterWeightInfo = function(data = {}) {
  return require({
    url: `${network}/${auto}/orderinfo/affirmCounterWeightInfo`,
    method: 'POST',
    data
  });
};
// 账单确认
export const billConfirmedRec = function(data = {}) {
  return require({
    url: `${network}/${auto}/orderinfo/billConfirmedRec`,
    method: 'POST',
    data
  });
};
// 商品详情
export const findGoodsById = function(data = {}) {
  return require({
    url: `${network}/${auto}/customergoods/findCustomerGoodsById`,
    method: 'POST',
    data
  });
};

// 商品TOP4
export const findOfferTop4 = function(data = {}) {
  return require({
    url: `${network}/${auto}/offerinfo/findOfferInfoFareTop4`,
    method: 'POST',
    data
  });
};

// 下载进仓单（提货地址存在）
export const downloadWarehouseNoByPickId1 = function(data) {
  return `${network}/${auto}/orderpick/downloadWarehouseNoByPickId1?id=${data}`;
};
// 下载箱唛（提货地址存在）
export const downloadWarehouseNoByPickId2 = function(data) {
  return `${network}/${auto}/orderpick/downloadWarehouseNoByPickId2?id=${data}`;
};
// 下载进仓单（提货地址不存在）
export const downloadWarehouseNoByOrderId1 = function(data) {
  return `${network}/${auto}/orderpick/downloadWarehouseNoByOrderId1?id=${data}`;
};
// 下载箱唛（提货地址不存在）
export const downloadWarehouseNoByOrderId2 = function(data) {
  return `${network}/${auto}/orderpick/downloadWarehouseNoByOrderId2?id=${data}`;
};

// 下载进仓单pdf（提货地址存在）
export const downloadPdfWarehouseNoByPickId1 = function(data) {
  return `${network}/${auto}/orderpick/downloadPdfWarehouseNoByPickId1?id=${data}`;
};
// 下载箱唛pdf（提货地址存在）
export const downloadPdfWarehouseNoByPickId2 = function(data) {
  return `${network}/${auto}/orderpick/downloadPdfWarehouseNoByPickId2?id=${data}`;
};
// 下载进仓单pdf（提货地址不存在）
export const downloadPdfWarehouseNoByOrderId1 = function(data) {
  return `${network}/${auto}/orderpick/downloadPdfWarehouseNoByOrderId1?id=${data}`;
};
// 下载箱唛pdf（提货地址不存在）
export const downloadPdfWarehouseNoByOrderId2 = function(data) {
  return `${network}/${auto}/orderpick/downloadPdfWarehouseNoByOrderId2?id=${data}`;
};
// 批量下载箱唛pdf
export const batchDownloadPdfWarehouseNoByOrderId2 = function(data) {
  return require({
    url: `${network}/${auto}/orderpick/downloadPdfWarehouseNoByOrderId2`,
    method: 'GET',
    params: data,
    responseType: 'blob'
  });
};
// 批量下载进仓单pdf
export const batchDownloadPdfWarehouseNoByOrderId1 = function(data) {
  return require({
    url: `${network}/${auto}/orderpick/downloadPdfWarehouseNoByOrderId1`,
    method: 'GET',
    params: data,
    responseType: 'blob'
  });
};
// 合并进仓单
// export const downloadWarehouseNoByOrderIds = function(data) {
//   return require({
//     url: `${network}/${auto}/orderpick/downloadWarehouseNoByOrderIds`,
//     method: 'GET',
//     params: data,
//     responseType: 'blob'
//   });
// };
// 合并进仓单
export const downloadWarehouseNoByOrderIds = function(data) {
  return `${network}/${auto}/orderpick/downloadWarehouseNoByOrderIds?orderIds=${data}`;
};

// 查询-订单状态
export const findOrderStatus = function(data = {}) {
  return require({
    url: `${network}/${auto}/orderinfo/findOrderStatus`,
    method: 'POST',
    data
  });
};
// 查询-服务名称
export const findServiceGroup = function(data = {}) {
  return require({
    url: `${network}/${auto}/servicegroup/findServiceGroup`,
    method: 'POST',
    data
  });
};
// 查询-运输方式
export const findTransportWay = function(data = {}) {
  return require({
    url: `${network}/${auto}/transportway/findTransportWay`,
    method: 'POST',
    data
  });
};
// 查询-目的仓库代码
export const findfabWarehouse = function(data = {}) {
  return require({
    url: `${network}/${auto}/fabwarehouse/findfabWarehouse`,
    method: 'POST',
    data
  });
};
// 查询应收FBA仓库(目的仓库)
export const findfabWarehouseByWarehouseCode = function(data = {}) {
  return require({
    url: `${network}/${auto}/fabwarehouse/findfabWarehouseByWarehouseCode`,
    method: 'POST',
    data
  });
};
// 查询-订单计费重确认状态
export const findOrderIsConfirmBilling = function(data = {}) {
  return require({
    url: `${network}/${auto}/orderinfo/findOrderIsConfirmBilling`,
    method: 'POST',
    data
  });
};
// 根据订单id，查询应收账单list
export const findReceivableBillMasterByOrderId = function(data = {}) {
  return require({
    url: `${network}/${auto}/receivablebillmaster/findReceivableBillMasterByOrderId`,
    method: 'POST',
    data
  });
};
// 应收账单-查看明细
export const lookDetail = function(data = {}) {
  return require({
    url: `${network}/${auto}/receivablebillmaster/lookDetail`,
    method: 'POST',
    data
  });
};
// 应收账单-下载账单
export const downloadBills = function(data) {
  return `${network}/${auto}/receivablebillmaster/downloadBills?ids=${data}`;
};

// 应收账单-确认账单
export const confirmBill = function(data = {}) {
  return require({
    url: `${network}/${auto}/receivablebillmaster/confirmBill`,
    method: 'POST',
    data
  });
};
// 下载
export function downLoadFiles(data = {}) {
  return Axios({
    method: 'GET',
    url: `${process.env.VUE_APP_BASEURL}/jayudFile/file/downloadFiles`,
    params: data,
    responseType: 'blob'
  });
}
