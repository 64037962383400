<template>
  <div class="commoditydetails">
    <div class="c-box head">
      <div class="hang">SKU: {{ forData.sku }}</div>
      <div class="hang">
        商品名称（中文）：{{ forData.nameCn }}
      </div>
      <div class="hang">商品名称（英文）：{{ forData.nameEn }}</div>
      <div class="hang">商品图片：</div>
      <div class="hang">条形码：{{ forData.barcode }}</div>
      <div class="hang">商品重量（KG）：{{ forData.weight }}</div>
      <div class="hang">尺寸（cm）：{{ forData.length }}*{{ forData.width }}*{{ forData.height }}</div>
      <div class="hang">商品类型：{{ forData.types==1?'普货':'特货' }}</div>
      <div class="hang">海关编码：{{ forData.hsCode }}</div>
      <div class="hang">销售链接：{{ forData.salesLink }}</div>
      <div class="hang">销售价格：{{ forData.salesPrice }}</div>
      <div v-if="!sc" class="bth-box" style="text-align:center;">
        <el-button
          round
          class="bth-adopt"
          @click="pop"
        >返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  findGoodsById
} from '@/api/order.js';
export default {
  name: 'Commoditydetails',
  props: ['id', 'sc'],
  data: function() {
    return {
      forData: {}
    };
  },
  computed: {},
  watch: {},
  created() {
    const id = this.$router.history.current.query.id;
    if (this.sc) {
      this.init();
    } else if (id) {
      this.init();
    } else {
      this.pop();
    }
  },
  mounted() {},
  methods: {
    init() {
      this.findGoodsById();
    },
    findGoodsById() {
      let goodId;
      if (this.sc) {
        goodId = this.id;
      } else {
        goodId = this.$route.query.id;
      }
      findGoodsById({ goodId }).then(result => {
        if (result.data.code == 0) {
          this.forData = result.data.data;
        } else {
          this.$message.error({
            message: result.data.msg
          });
        }
      }).catch(error => {
        try {
          if (error.response) {
            this.$message.error({
              message: error.response.data.msg
            });
          } else {
            this.$message.error({
              message: '请求失败'
            });
          }
        } catch (err) {
          this.$message.error({
            message: err
          });
        }
      });
    },
    pop() {
      this.$router.go(-1);
    },
    goto(route) {
      this.$router.push({ path: route });
    }
  }
};
</script>

<style scoped>
.head{margin-top:20px}
.hang {
  line-height: 40px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.bth-adopt{width:120px;}
</style>
